<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Display Text</div>
                    <div class="card-body">
                        <div class="kanban-container">
                            <kanban variant="warning">
                                <template #title>Backlog</template>
                                <template #dropdown>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </template>
                                <template #default>
                                    <kanban-item v-for="(item,index) in backlog" :key="index"
                                                 :title="item.title"
                                                 :badge="item.badge"
                                                 :pin-count="item.pinCount"
                                                 :comment-count="item.commentCount"
                                                 :avatar="item.avatarSrc"
                                    />
                                </template>
                            </kanban>

                            <kanban>
                                <template #title>In Progress</template>
                                <template #dropdown>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </template>
                                <template #default>
                                    <kanban-item v-for="(item,index) in inProgress" :key="index"
                                                 :title="item.title"
                                                 :badge="item.badge"
                                                 :pin-count="item.pinCount"
                                                 :comment-count="item.commentCount"
                                                 :avatar="item.avatarSrc"
                                    />
                                </template>
                            </kanban>

                            <kanban variant="custom-info-dark">
                                <template #title>In Review</template>
                                <template #dropdown>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </template>
                                <template #default>
                                    <kanban-item v-for="(item,index) in inReview" :key="index"
                                                 :title="item.title"
                                                 :badge="item.badge"
                                                 :pin-count="item.pinCount"
                                                 :comment-count="item.commentCount"
                                                 :avatar="item.avatarSrc"
                                    />
                                </template>
                            </kanban>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Kanban from "../../../src/components/Kanban/Kanban";
    import KanbanItem from "../../../src/components/KanbanItem/KanbanItem";

    export default {
        name: "Kanbans",
        components: {
            Kanban,
            KanbanItem
        },
        data() {
            return {
                backlog: [
                    {
                        badge: {
                            variant: 'secondary',
                            text: 'Low Priority'
                        },
                        title: 'Website Design',
                    },
                    {
                        badge: {
                            variant: 'danger',
                            text: 'Past Due'
                        },
                        title: 'Content Plan',
                    },
                    {
                        badge: {
                            variant: 'success',
                            text: 'Complete'
                        },
                        title: 'Graphics',
                    },
                    {
                        badge: {
                            variant: 'success',
                            text: 'Complete'
                        },
                        title: 'Typography',
                    }
                ],

                inProgress: [
                    {
                        badge: {
                            variant: 'danger',
                            text: 'High Priority'
                        },
                        title: 'Dashboard Design',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Mid Priority'
                        },
                        title: 'Bootstrap v4 Investigation',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Mid Priority'
                        },
                        title: 'Card Title',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Badge'
                        },
                        title: 'Card Title',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Badge'
                        },
                        title: 'Card Title',
                    },
                ],
                inReview: [
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Badge'
                        },
                        title: 'Card Title',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Badge'
                        },
                        title: 'Card Title',
                    },
                    {
                        badge: {
                            variant: 'primary',
                            text: 'Badge'
                        },
                        title: 'Card Title',
                    },
                ]
            }
        }
    }
</script>
