<template>
    <div class="kanban-item">
        <div class="kanban-badge">
            <span :class="['badge', {[`badge-${badge.variant}`] : true} ]">
                {{ badge.text }}
            </span>
            <div class="font-18 font-weight-medium my-3">
                {{ title }}
            </div>
            <div class="kanban-footer">
                <div class="font-18 text-secondary">
                    <span class="mr-2">
                        <fa class="mr-1" icon="paperclip"></fa>
                        3
                    </span>
                    <span>
                        <fa class="mr-1" :icon="['far','comment-alt']"></fa>
                        2
                    </span>
                </div>

                <div class="d-flex align-items-center text-light-dark">
                    <span>
                        <fa class="mr-3" icon="plus"></fa>
                    </span>
                    <avatar type="image" src="https://i.pravatar.cc/150?img=52" size="sm"></avatar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Avatar from "../Avatar"

    export default {
        name: "KanbanItem",
        components: {
            Avatar
        },
        props: [
            'title', 'badge', 'pinCount', 'commentCount', 'avatar'
        ]
    }
</script>
