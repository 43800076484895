<template>
    <div class="kanban">
        <div :class="['kanban-top-border', { [`bg-${variant}`]:true }]"></div>
        <div class="kanban-header">
            <span class="title">
                <slot name="title"></slot>
            </span>
            <div class="dropdown-icon px-1" data-toggle="dropdown">
                <fa icon="ellipsis-v" size="sm"></fa>
            </div>
            <div class="dropdown-menu">
                <slot name="dropdown"></slot>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <div class="action-button">
            <span class="mr-2">Add Task</span>
            <fa icon="plus-circle"></fa>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Kanban",
        props: {
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'custom-info-dark', 'dark'].indexOf(value) !== -1
                }
            }
        }
    }
</script>
